export enum CaseType {
  sr = 1,
  sp = 2,
  szl = 4,
}

export const caseTypesEi = [
  {
    src: '/cases/ei-sr.webp',
    name: 'Volně stojící skřín SR',
    type: CaseType.sr,
  },
  {
    src: '/cases/ei-sp.webp',
    name: 'Rozvaděč na omítku SP',
    type: CaseType.sp,
  },
  {
    src: '/cases/ei-sz-l.webp',
    name: 'Rozváděč pod omítku s vlastní vanou SZ-L',
    type: CaseType.szl,
  },
]

export const caseTypesP = [
  {
    src: '/cases/p-sr.webp',
    name: 'Skříně na podlahu F-SR-N',
    type: CaseType.sr,
  },
  {
    src: '/cases/p-sp.webp',
    name: 'Skříně na omítku F-SP',
    type: CaseType.sp,
  },
]
