import type { Case } from './Case'
import { ElectricityCover } from './ElectricityCover'
import { Mask, MaskType } from './Mask'

export class Fill {
  public static readonly toolGrid = 1
  public static readonly mountPanel = 2
  public static readonly emptyCase = 3
  public static readonly noneOnlyDoors = 4
  public static readonly electricityPrepare = 5
  public static readonly none = 6

  public fillValue: number

  constructor () {
    this.fillValue = Fill.none
  }

  static fromJson (json: Record<string, any>): Fill {
    switch (json.fill_value as number) {
      case Fill.toolGrid:
        return ToolGrid.fromJson(json)
      case Fill.mountPanel:
        return MountPanel.fromJson()
      case Fill.emptyCase:
        return EmptyCase.fromJson()
      case Fill.electricityPrepare:
        return ElectricityPrepare.fromJson(json)
      case Fill.noneOnlyDoors:
        return NoneOnlyDoors.fromJson()
      default:
        return new None()
    }
  }

  public toJson (): Record<string, any> {
    return {}
  }

  public get hasDins (): boolean {
    return false
  }

  public get asToolGrid (): ToolGrid {
    return (this as unknown as ToolGrid)
  }

  public get asElectricity (): ElectricityPrepare {
    return (this as unknown as ElectricityPrepare)
  }

  public get asInt (): number | null {
    if (this.toolGrid)
      return Fill.toolGrid
    if (this.mountPanel)
      return Fill.mountPanel
    if (this.emptyCase)
      return Fill.emptyCase
    if (this.noneOnlyDoors)
      return Fill.noneOnlyDoors
    if (this.electricityPrepare)
      return Fill.electricityPrepare
    if (this.none)
      return Fill.none

    return null
  }

  public get toolGrid (): ToolGrid | undefined {
    return this instanceof ToolGrid ? this as ToolGrid : undefined
  }

  public get mountPanel (): MountPanel | undefined {
    return this instanceof MountPanel ? this as MountPanel : undefined
  }

  public get emptyCase (): EmptyCase | undefined {
    return this instanceof EmptyCase ? this as EmptyCase : undefined
  }

  public get noneOnlyDoors (): NoneOnlyDoors | undefined {
    return this instanceof NoneOnlyDoors ? this as NoneOnlyDoors : undefined
  }

  public get electricityPrepare (): ElectricityPrepare | undefined {
    return this instanceof ElectricityPrepare ? this as ElectricityPrepare : undefined
  }

  public get none (): None | undefined {
    return this instanceof None ? this as None : undefined
  }
}
export enum ToolGridContent {
  full = 1,
  onlyPrepare = 2,
  onlySides = 3,
}

export class ToolGrid extends Fill {
  constructor (
    public content?: ToolGridContent,
    public masks?: Mask[],
  ) {
    super()
    this.fillValue = Fill.toolGrid
    this.masks = masks ?? []
  }

  static override fromJson (json: Record<string, any>): ToolGrid {
    return new ToolGrid(
      json.content,
      json.masks.map(Mask.fromJson),
    )
  }

  public override toJson (): Record<string, any> {
    return {
      fill_value: Fill.toolGrid,
      masks: this.masks?.map(m => m.toJson()) ?? [],
      content: this.content,
    }
  }

  public get hasSides (): boolean {
    return this.content === ToolGridContent.full || this.content === ToolGridContent.onlySides
  }

  public override get hasDins (): boolean {
    return this.content === ToolGridContent.full
  }

  public get height (): number {
    let filled = 0
    for (const item of this.masks!)
      filled += item.height

    return filled
  }

  public getMaskHeight (indexFrom: number, indexTo: number): number {
    let height = 0
    let index = 0
    for (const mask of this.masks!) {
      if (index >= indexFrom && index <= indexTo)
        height += mask.height

      index++
    }
    return height
  }

  public addMask (mask: Mask): void {
    this.masks!.push(mask)
  }

  public removeMask (mask: Mask): void {
    const index = this.masks!.indexOf(mask)
    this.masks!.splice(index, 1)
  }

  public resetMasks (): void {
    this.masks = []
  }
}

export class ElectricityPrepare extends Fill {
  constructor (
    public masksTop?: Mask[],
    public masksBottom?: Mask[],
    public covers?: ElectricityCover[],
  ) {
    super()
    this.fillValue = Fill.electricityPrepare
    this.masksTop ??= []
    this.masksBottom ??= []
    this.covers ??= []
  }

  static override fromJson (json: Record<string, any>): ElectricityPrepare {
    return new ElectricityPrepare(
      json.masks_top.map(Mask.fromJson),
      json.masks_bottom.map(Mask.fromJson),
      json.covers.map(ElectricityCover.fromJson),
    )
  }

  public override toJson (): Record<string, any> {
    return {
      fill_value: Fill.electricityPrepare,
      masks_top: this.masksTop?.map(m => m.toJson()) ?? [],
      masks_bottom: this.masksBottom?.map(m => m.toJson()) ?? [],
      covers: this.covers?.map(c => c.toJson()) ?? [],
    }
  }

  public get heightTop (): number {
    let filled = 0
    for (const item of this.masksTop!)
      filled += item?.height
    return filled
  }

  public get heightBottom (): number {
    let filled = 0
    for (const item of this.masksBottom!)
      filled += item?.height

    return filled
  }

  public get heightCovers (): number {
    let filled = 0
    for (const item of this.covers!)
      filled += item.height

    return filled
  }

  public get desksCount (): number {
    return this.covers!.length * this.covers![0].moduleCount
  }

  public getMaskTopHeight (indexFrom: number, indexTo: number): number {
    let height = 0
    let index = 0
    for (const mask of this.masksTop!) {
      if (index >= indexFrom && index <= indexTo)
        height += mask.height

      index++
    }
    return height
  }

  public getMaskBottomHeight (indexFrom: number, indexTo: number): number {
    let height = 0
    let index = 0
    for (const mask of this.masksBottom!) {
      if (index >= indexFrom && index <= indexTo)
        height += mask.height

      index++
    }
    return height
  }

  public getElectricityCoverHeight (indexFrom: number, indexTo: number): number {
    let height = 0
    let index = 0
    for (const cover of this.covers!) {
      if (index >= indexFrom && index <= indexTo)
        height += cover.height

      index++
    }
    return height
  }

  public prepareElectricity (caseInfo: Case, caseMasks: Mask[]): void {
    const masks = caseMasks.filter(mask => mask.fill.includes(this.fillValue)).map(mask => {
      const maskCopy = Mask.fromJson(mask.toJson())
      maskCopy.label = maskCopy.label.replaceAll('XXX', (Math.floor(caseInfo.outDimensions.w / 100) * 100).toString())
      return maskCopy
    })

    if (caseInfo.outDimensions.h <= 1600)
      this.masksTop!.push(masks.find(mask => mask.height === 200)!)

    else
      this.masksTop!.push(masks.find(mask => mask.height === 300)!)

    const rows = Math.floor(((caseInfo.outDimensions.h * 0.6) / 400))

    for (let i = 1; i <= rows; i++) {
      this.covers!.push(new ElectricityCover(
        'label',
        400,
        Math.floor(((caseInfo.inDimensions.w - 20) / 200)),
      ))
    }

    this.masksBottom!.push(masks.find(mask => mask.height === 150 && mask.type === MaskType.filled)!)

    this.autoAddBottomMask(caseInfo, masks)
  }

  public autoAddBottomMask (caseInfo: Case, masks: Mask[]): void {
    const freeSpace
    = caseInfo.inDimensions.h - this.heightTop - this.heightBottom - this.heightCovers
    const availableMasks = masks.filter(mask => mask.type === MaskType.filled && mask.height <= freeSpace)
    if (availableMasks.length === 0)
      return

    this.masksBottom!.push(
      availableMasks.find(m => m.height === freeSpace)
      || availableMasks.find(m => m.height === freeSpace / 2)
      || availableMasks.find(m => m.height === 150)
      || availableMasks[availableMasks.length - 1],
    )
    this.autoAddBottomMask(caseInfo, availableMasks)
  }
}

export class MountPanel extends Fill {
  constructor () {
    super()
    this.fillValue = Fill.mountPanel
  }

  static override fromJson (): MountPanel {
    return new MountPanel()
  }

  public override toJson (): Record<string, any> {
    return {
      fill_value: Fill.mountPanel,
    }
  }
}

export class EmptyCase extends Fill {
  constructor () {
    super()
    this.fillValue = Fill.emptyCase
  }

  static override fromJson (): EmptyCase {
    return new EmptyCase()
  }

  public override toJson (): Record<string, any> {
    return {
      fill_value: Fill.emptyCase,
    }
  }
}

export class NoneOnlyDoors extends Fill {
  constructor () {
    super()
    this.fillValue = Fill.noneOnlyDoors
  }

  static override fromJson (): NoneOnlyDoors {
    return new NoneOnlyDoors()
  }

  public override toJson (): Record<string, any> {
    return {
      fill_value: Fill.noneOnlyDoors,
    }
  }
}

export class None extends Fill {}
