import type { Cover } from '~/types'
import { Case, Din, Mask, Side, SidePosition, Ventilation } from '~/types'
import type { CaseType } from '~/types/CaseType'
import type { Country } from '~/types/Country'
import type { Dimensions } from '~/types/Dimensions'
import { CustomSizeType } from '~/types/SizeType'

export const useRemoteConfig = defineStore('remote-config', () => {
  const cases = useState<Case[]>(() => [])
  const masks = useState<Mask[]>(() => [])
  const ventilations = useState<Ventilation[]>(() => [])
  const covers = useState<Cover[]>(() => [])
  const sides = useState<Side[]>(() => [])
  const sidePositions = useState<SidePosition[]>(() => [])
  const dins = useState<Din[]>(() => [])

  const countries: Country[] = [
    { id: 'CZ', name: 'Česká republika' },
    { id: 'SK', name: 'Slovensko' },
    { id: 'PL', name: 'Polsko' },
    { id: 'DE', name: 'Německo' },
    { id: 'AT', name: 'Rakousko' },
  ]

  const countryOptions = computed(() =>
    countries.map(c => ({
      label: c.name,
      value: c.id,
    })),
  )

  async function fetchConfig () {
    try {
      const data = await $fetch('/api/remote-config', { method: 'GET' })

      const value = superjsonParse<{
        cases: Case[]
        masks: Mask[]
        ventilations: Ventilation[]
        covers: Cover[]
        sides: Side[]
        sidePositions: SidePosition[]
        dins: Din[]
      }>(data)

      cases.value = value!.cases.map(c => Case.fromJson(c))
      masks.value = value!.masks.map(m => Mask.fromJson(m))
      ventilations.value = value!.ventilations.map(v => Ventilation.fromJson(v))
      covers.value = value!.covers
      sides.value = value!.sides.map(s => Side.fromJson(s))
      sidePositions.value = value!.sidePositions.map(sp => SidePosition.fromJson(sp))
      dins.value = value!.dins.map(d => Din.fromJson(d))
    }
    catch (error) {
      console.error('Failed to fetch remote config', error)
    }
  }

  const findCatalogCase = (ct: CaseType, ei: number, cst: CustomSizeType, dim: Dimensions) => {
    return cases.value.find(c => {
      if (cst === CustomSizeType.outerDimensions)
        return (c.caseType === ct && c.ei === ei && c.outDimensions.w === dim.w && c.outDimensions.h === dim.h && c.outDimensions.d === dim.d)
      else
        return (c.caseType === ct && c.ei === ei && c.inDimensions.w === dim.w && c.inDimensions.h === dim.h && c.inDimensions.d === dim.d)
    })
  }

  useAsyncData(() => fetchConfig())

  return {
    countries,
    countryOptions,
    cases,
    masks,
    ventilations,
    covers,
    sides,
    sidePositions,
    dins,
    findCatalogCase,
  }
})
