import { CaseConfig } from './CaseConfig'

export class OrderItem {
  constructor (
    public title: string,
    public quantity: number,
    public caseConfig: CaseConfig,
    public price: number,
    public description: string,
  ) { }

  static fromJson (json: Record<string, any>): OrderItem {
    return new OrderItem(
      json.title,
      json.quantity,
      CaseConfig.fromJson(json.case_config),
      json.price,
      json.description,
    )
  }

  public toJson (): Record<string, any> {
    return {
      title: this.title,
      quantity: this.quantity,
      case_config: this.caseConfig.toJson(),
      price: this.price,
      description: this.description,
    }
  }

  public get sum (): number {
    return this.price * (this.quantity ?? 1)
  }

  public get deliveryWeight (): number {
    return (((this.caseConfig.caseInfo!.outDimensions.h / 1000)
      * (this.caseConfig.caseInfo!.outDimensions.w / 1000)
      * ((this.caseConfig.caseInfo!.outDimensions.d / 1000) + 0.15))
    * 250)
  }
}
