import { caseTypesEi, caseTypesP } from './CaseType'

export enum CaseSerie {
  ei = 'ei',
  p = 'p',
}

export const caseSeries = [
  {
    src: '/cases/ei.webp',
    name: 'Skříně s požární odolností EI',
    serie: CaseSerie.ei,
    caseTypes: caseTypesEi,
  },
  {
    src: '/cases/p.webp',
    name: 'Skříně s funkční schopností při požáru Px-R',
    serie: CaseSerie.p,
    caseTypes: caseTypesP,
  },
]
