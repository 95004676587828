import type { CaseSerie } from './CaseSerie'
import type { CaseType } from './CaseType'
import { Dimensions } from './Dimensions'
import { FillPrice } from './FillPrice'

export class Case {
  constructor (
    public caseSerie: CaseSerie,
    public caseType: CaseType,
    public ei: number,
    public inDimensions: Dimensions,
    public outDimensions: Dimensions,
    public weight: number,
    public label: string,
    public pv: number,
    public orderNumber: number,
    public casePrice: number,
    public fillPrice?: FillPrice,
    public modules?: number,
    public stock?: boolean,
  ) { }

  static fromJson (json: Record<string, any>): Case {
    return new Case(
      json.case_serie,
      json.case_type,
      json.ei,
      Dimensions.fromJson(json.in_dimensions),
      Dimensions.fromJson(json.out_dimensions),
      json.weight,
      json.label,
      json.pv,
      json.order_number,
      json.case_price,
      json.fill_price ? FillPrice.fromJson(json.fill_price) : undefined,
      json.modules,
      json.stock,
    )
  }

  toJson (): Record<string, any> {
    return {
      case_serie: this.caseSerie,
      case_type: this.caseType,
      ei: this.ei,
      in_dimensions: this.inDimensions?.toJson(),
      out_dimensions: this.outDimensions?.toJson(),
      weight: this.weight,
      label: this.label,
      pv: this.pv,
      order_number: this.orderNumber,
      case_price: this.casePrice,
      fill_price: this.fillPrice?.toJson(),
      modules: this.modules,
      stock: this.stock,
    }
  }

  public get caseHeightCeil (): number {
    return Math.floor(this.outDimensions.h / 100) * 100
  }

  public get caseWidthCeil (): number {
    return Math.floor(this.outDimensions.w / 100) * 100
  }
}
