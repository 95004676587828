import { useModal } from 'vue-final-modal'
import ModalLogin from '~/components/Modal/ModalLogin.vue'
import ModalRegister from '~/components/Modal/ModalRegister.vue'
import ModalPassword from '~/components/Modal/ModalPassword.vue'
import ModalCart from '~/components/Modal/ModalCart.vue'
import ModalOrder from '~/components/Modal/ModalOrder.vue'
import ModalOrderComplete from '~/components/Modal/ModalOrderComplete.vue'

const { open: openRegister, close: closeRegister } = useModal({
  component: ModalRegister,
  attrs: {
    onClose () {
      closeRegister()
    },
    onFinish () {
      closeRegister()
    },
  },
})

const { open: openLogin, close: closeLogin } = useModal({
  component: ModalLogin,
  attrs: {
    onClose () {
      closeLogin()
    },
    onRegister () {
      closeLogin()
      openRegister()
    },
  },
})

const { open: openPassword, close: closePassword } = useModal({
  component: ModalPassword,
  attrs: {
    onClose: () => {
      closePassword()
    },
    onSetPassword: () => {
      openLogin()
    },
  },
})

const { open: openCart, close: closeCart } = useModal({
  component: ModalCart,
})

const { open: openOrder, close: closeOrder } = useModal({
  component: ModalOrder,
})

const { open: openOrderComplete, close: closeOrderComplete } = useModal({
  component: ModalOrderComplete,
})

export default function useModals (): any {
  return {
    openLogin,
    closeLogin,
    openRegister,
    closeRegister,
    openPassword,
    closePassword,
    openCart,
    closeCart,
    openOrder,
    closeOrder,
    openOrderComplete,
    closeOrderComplete,
  }
}
