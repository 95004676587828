export class Din {
  constructor (
    public label: string,
    public width: number,
  ) {}

  static fromJson (json: Record<string, any>): Din {
    return new Din(json.label, json.width)
  }

  public toJson (): Record<string, any> {
    return {
      label: this.label,
      width: this.width,
    }
  }
}
