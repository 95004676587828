import { promiseTimeout } from '@vueuse/core'
import type { DestinationDistance } from '~/types/DestinationDistance'
import type { Order } from '~/types/Order'

export default function useDelivery () {
  const [loadingDelivery, toggleLoadingDelivery] = useToggle(false)
  const { showAlert } = useAlert()

  async function calcDelivery (order: Order) {
    await promiseTimeout(100)

    const individualDelivery = order.deliveryAddress?.country !== 'CZ'
    if (!order.deliveryAddress?.city || !order.deliveryAddress?.zip || !order.withDelivery || individualDelivery)
      return {
        deliveryKm: 0,
        deliveryPrice: 0,
      }

    toggleLoadingDelivery(true)
    const q = `${order.deliveryAddress.city} ${order.deliveryAddress.zip} CZ`
    const result = await $fetch(`/api/delivery-distance?q=${q}`)
    const destinationDistance = superjsonParse<DestinationDistance>(result)

    if (!destinationDistance) {
      showAlert({
        intent: 'danger',
        title: 'Nepodařilo se spočítat cenu dopravy, zkuste to znovu', seconds: 5,
      })
      return {
        deliveryKm: 0,
        deliveryPrice: 0,
      }
    }

    const casesWeight = order.items?.map(item => item.deliveryWeight * item.quantity).reduce((sum, w) => sum + w) || 0

    let coeficient = 0.25
    if (destinationDistance.distance < 250) coeficient = 0.03
    if (destinationDistance.distance < 150) coeficient = 0.04
    if (destinationDistance.distance < 100) coeficient = 0.05
    if (destinationDistance.distance < 40) coeficient = 0.08

    if (casesWeight > 400) coeficient -= 0.01

    order.deliveryKm = destinationDistance.distance

    let price = 0.0

    for (const i of (order.items || [])) {
      let iPrice = destinationDistance.distance * i.deliveryWeight * coeficient
      if (i.caseConfig.isSzl && iPrice > 650) iPrice = 650
      if (i.caseConfig.isSp && iPrice > 920) iPrice = 920
      if (i.caseConfig.isSr && iPrice > 1120) iPrice = 1120

      price += iPrice * i.quantity
    }

    price = destinationDistance.distance * casesWeight * coeficient
    if (price > 5000) price = 2000
    if (price < 600) price = 600

    toggleLoadingDelivery()

    return {
      deliveryKm: destinationDistance.distance,
      deliveryPrice: price,
    }
  }

  return {
    loadingDelivery,
    calcDelivery,
  }
}
