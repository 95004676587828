import payload_plugin_0Xpss418cr from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.29.2/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import payload_plugin_2WFa6C4zRU from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.0.2_encoding@0.1.13__firebase-_juswtmv6nju6ifuie3ouw62qnu/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_ZdJvSj5Yl0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_7beebuxeyjmlsyvlpszk7f3r6e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hqABXjSAzu from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_7beebuxeyjmlsyvlpszk7f3r6e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_KrBdECxbZd from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_7beebuxeyjmlsyvlpszk7f3r6e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_80iHdIeoFm from "/vercel/path0/node_modules/.pnpm/@develit-io+nuxt-sentry@0.4.2_encoding@0.1.13_magicast@0.3.5_rollup@4.29.2_vue@3.5.13_typescript@5.7.2_/node_modules/@develit-io/nuxt-sentry/dist/runtime/sentry.client.mjs";
import payload_client_y5TkQiUbNg from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_7beebuxeyjmlsyvlpszk7f3r6e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_rTtBqzcKS7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_7beebuxeyjmlsyvlpszk7f3r6e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dXqoN3kEfv from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_7beebuxeyjmlsyvlpszk7f3r6e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_c7xPP5Jkwn from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_7beebuxeyjmlsyvlpszk7f3r6e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_dyPyb46U5S from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.29.2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_6vClIdfaai from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_7beebuxeyjmlsyvlpszk7f3r6e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/vercel/path0/.nuxt/floating-vue.mjs";
import plugin_client_P8FsrI1FPC from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.0.2_encoding@0.1.13__firebase-_juswtmv6nju6ifuie3ouw62qnu/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/vercel/path0/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/vercel/path0/.nuxt/vuefire-plugin.mjs";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import auto_animate_uTpJuTGsMC from "/vercel/path0/plugins/auto-animate.ts";
import final_modal_aCbWmDLqxD from "/vercel/path0/plugins/final-modal.ts";
import resizable_BtCGBtXSJh from "/vercel/path0/plugins/resizable.ts";
export default [
  payload_plugin_0Xpss418cr,
  payload_plugin_2WFa6C4zRU,
  revive_payload_client_ZdJvSj5Yl0,
  unhead_hqABXjSAzu,
  router_KrBdECxbZd,
  sentry_client_80iHdIeoFm,
  payload_client_y5TkQiUbNg,
  navigation_repaint_client_rTtBqzcKS7,
  check_outdated_build_client_dXqoN3kEfv,
  chunk_reload_client_c7xPP5Jkwn,
  plugin_vue3_dyPyb46U5S,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6vClIdfaai,
  floating_vue_EIcJ7xiw0h,
  plugin_client_P8FsrI1FPC,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  unocss_MzCDxu9LMj,
  auto_animate_uTpJuTGsMC,
  final_modal_aCbWmDLqxD,
  resizable_BtCGBtXSJh
]