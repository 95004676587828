<script lang="ts" setup>
import type { CaseConfig } from '~/types/CaseConfig'
import { CaseSerie } from '~/types/CaseSerie'
import { caseTypesEi, caseTypesP } from '~/types/CaseType'
import type { CaseType } from '~/types/CaseType'

const props = defineProps<{
  caseConfig: CaseConfig
  quantity?: number
  readonly?: boolean
}>()

const emits = defineEmits<{
  (e: 'quantityChange', quantity: number): void
  (e: 'remove' | 'edit'): void
}>()

const quantity = ref<number>(props.quantity ?? 1)
const [expanded, toggleExpanded] = useToggle(false)

function getCaseTypeInfo (caseType: CaseType) {
  return (props.caseConfig.caseSerie === CaseSerie.p ? caseTypesP : caseTypesEi).find(type => type.type === caseType)
}

watch(quantity, () => emits('quantityChange', quantity.value || 0))
</script>

<template>
  <div
    grid grid-cols-4 :class="[expanded ? 'grid-items-start' : 'grid-items-center']" gap-24px w-full bg-app-bg-card
    p-16px rounded-lg
  >
    <NuxtImg :src="getCaseTypeInfo(caseConfig.caseType!)!.src" :height="expanded ? 192 : 64" />
    <div col-span-3>
      <div flex items-center justify-between gap-16px>
        <div>
          <h1 text="16px black">
            {{ caseConfig.caseLabel.split('DP')[0] }}
          </h1>
          <h2 text="12px">
            {{ getCaseTypeInfo(caseConfig.caseType!)!.name }}
          </h2>
        </div>
        <KInput v-if="!readonly" v-model="quantity" type="number" suffix="ks" suffix-size="14" :name="caseConfig.id!" max-w-80px />
        <div flex items-center gap-16px>
          <KInput v-if="!!readonly" v-model="quantity" type="number" suffix="ks" suffix-size="14" :name="caseConfig.id!" max-w-80px readonly />
          <button type="button" text-btn underline @click="toggleExpanded()">
            {{ expanded ? 'Sbalit' : 'Rozbalit' }}
          </button>
        </div>
        <UnoIcon v-if="!readonly" i-heroicons-solid-pencil text="24px app-blue" cursor-pointer @click="emits('edit')" />
        <UnoIcon v-if="!readonly" ml--12px i-heroicons-solid-x text="24px app-red" cursor-pointer @click="emits('remove')" />
      </div>
      <div v-if="expanded" mt-16px>
        <SummaryItems :config="caseConfig" />
        <div grid grid-cols-12 text-12px py-6px border-t="1px app-stroke" w-full>
          <p col-span-4>
            Celková cena
          </p>
          <p col-span-8 font-semibold>
            {{ formatPrice(caseConfig.casePrice * quantity) }} Kč bez DPH
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
