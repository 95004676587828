
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as companyExefO5ebyNMeta } from "/vercel/path0/pages/admin/company.vue?macro=true";
import { default as indexj5bBvmrvjTMeta } from "/vercel/path0/pages/admin/customers/detail/[id]/index.vue?macro=true";
import { default as ordersFv8K7WaxsdMeta } from "/vercel/path0/pages/admin/customers/detail/[id]/orders.vue?macro=true";
import { default as indexQ7iazSzn4hMeta } from "/vercel/path0/pages/admin/customers/index.vue?macro=true";
import { default as indexG7R2Mya2P4Meta } from "/vercel/path0/pages/admin/index.vue?macro=true";
import { default as _91code_93HVbBn39jvhMeta } from "/vercel/path0/pages/admin/orders/detail/[code].vue?macro=true";
import { default as indexzXLACf6OWSMeta } from "/vercel/path0/pages/admin/orders/index.vue?macro=true";
import { default as _91id_93WdtiyoFGmpMeta } from "/vercel/path0/pages/admin/sales/[id].vue?macro=true";
import { default as indexPxLtsUSYDTMeta } from "/vercel/path0/pages/admin/sales/index.vue?macro=true";
import { default as usersjvZRxTajERMeta } from "/vercel/path0/pages/admin/users.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93wbZdSH9GBrMeta } from "/vercel/path0/pages/pdf/[id].vue?macro=true";
export default [
  {
    name: "admin-company",
    path: "/admin/company",
    meta: companyExefO5ebyNMeta || {},
    component: () => import("/vercel/path0/pages/admin/company.vue")
  },
  {
    name: "admin-customers-detail-id",
    path: "/admin/customers/detail/:id()",
    meta: indexj5bBvmrvjTMeta || {},
    component: () => import("/vercel/path0/pages/admin/customers/detail/[id]/index.vue")
  },
  {
    name: "admin-customers-detail-id-orders",
    path: "/admin/customers/detail/:id()/orders",
    meta: ordersFv8K7WaxsdMeta || {},
    component: () => import("/vercel/path0/pages/admin/customers/detail/[id]/orders.vue")
  },
  {
    name: "admin-customers",
    path: "/admin/customers",
    meta: indexQ7iazSzn4hMeta || {},
    component: () => import("/vercel/path0/pages/admin/customers/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/vercel/path0/pages/admin/index.vue")
  },
  {
    name: "admin-orders-detail-code",
    path: "/admin/orders/detail/:code()",
    meta: _91code_93HVbBn39jvhMeta || {},
    component: () => import("/vercel/path0/pages/admin/orders/detail/[code].vue")
  },
  {
    name: "admin-orders",
    path: "/admin/orders",
    meta: indexzXLACf6OWSMeta || {},
    component: () => import("/vercel/path0/pages/admin/orders/index.vue")
  },
  {
    name: "admin-sales-id",
    path: "/admin/sales/:id()",
    meta: _91id_93WdtiyoFGmpMeta || {},
    component: () => import("/vercel/path0/pages/admin/sales/[id].vue")
  },
  {
    name: "admin-sales",
    path: "/admin/sales",
    meta: indexPxLtsUSYDTMeta || {},
    component: () => import("/vercel/path0/pages/admin/sales/index.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: usersjvZRxTajERMeta || {},
    component: () => import("/vercel/path0/pages/admin/users.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "pdf-id",
    path: "/pdf/:id()",
    meta: _91id_93wbZdSH9GBrMeta || {},
    component: () => import("/vercel/path0/pages/pdf/[id].vue")
  }
]